import React from 'react';
import {Helmet} from "react-helmet";


import HeaderV2 from "../../components/HeaderV2/HeaderV2";
import HoverCards from "./AggregatesHoverCards"
import Footer from "../../components/Footer/Footer";
import ContactUsBtn from "../../components/ContactUsBtn";


import "../../../styles/ServicesPages.scss";
import SEO from "../../components/SEO";
import {StaticImage} from "gatsby-plugin-image";

function AggregatesTesting() {

    return (
        <div>
            <SEO title={"Aggregate Testing - QCTesting"} description={"List of Aggregate testing we conduct"} url={"www.qctesting.com.au/services/AggregateTestin"}
            image={"../../images/UteOnSite.webp"} keywords={"quarry testing, rock testing, flakiness index, flakiness index test, aggregate rock, flakiness and elongation test, aggregate in construction, " +
                "aggregate testing, aggregate, construction material testing, construction testing, material testing, flexural strength of concrete,  in aggregate, " +
                "soil testing for construction"}/>
            <div>
                <HeaderV2/>
                <div className={"servicesHead"}>
                    <StaticImage
                        className={"headImage"}
                        src={"../../images/UteOnSite.webp"}
                        alt={"Qctesting ute on site with truck in background"}
                        placeholder={"blurred"}
                        objectFit={"cover"}
                    />
                    <h1> Aggregate testing </h1>
                </div>
                    <div className={"boxShadow"}/>
                    <h1 id={"g"}> Tests We Conduct </h1>
                        <HoverCards/>
                <Footer/>
                <div /* Fix Position Items  */ >
                    <ContactUsBtn className={"contactBtnPosition"} redirect={"Tel:0408623377"}/>
                </div>

            </div>
        </div>
    );
}

export default AggregatesTesting;
